import {ControllerParams} from '@wix/yoshi-flow-editor';
import {
  CheckoutNavigationService,
  DynamicPaymentMethodsShape,
  DynamicPaymentMethodsTheme,
  QueryParamsService,
  SiteStore,
  StoreMetaDataApi,
} from '@wix/wixstores-client-storefront-sdk';
import {StoresWidgetID} from '@wix/wixstores-client-core';
import {CartService} from '../services/CartService';
import {PaymentBreakdown} from '@wix/cashier-express-checkout-widget/dist/src/types/PaymentBreakdown';
import {StoreMetaDataService} from '../services/StoreMetaDataService';
import {CreateCheckoutExceptions, PaymentMethodType} from '../../common/constants';
import {ButtonStyle, Theme} from '@wix/cashier-express-checkout-widget/dist/src/types/Styles';
import {StyleSettingsService} from '../services/StyleSettingsService';
import {BIService} from '../services/BIService';
import {OrderService} from '../services/OrderService';
import {ICartControllerApi} from '../../types/app.types';
import {NavigationService} from '../services/NavigationService';
import {ModalManagerService} from '../services/ModalManagerService';
import {MinimumOrderAmountService} from '../services/MinimumOrderAmountService';
import {
  createExpressCheckoutButtonsController,
  ExpressCheckoutButtonsController,
} from '@wix/ecom-platform-express-checkout-buttons';
import {handleCreateCheckoutErrors} from '../utils/handleCreateCheckoutErrors';

export interface PaymentBreakdownAndAmount {
  paymentBreakdown: PaymentBreakdown;
  paymentAmount: string;
}

export class CashierExpressStore {
  private readonly cartService: CartService;
  private readonly navigationService: NavigationService;
  private readonly checkoutNavigationService: CheckoutNavigationService;
  private readonly storeMetaDataService: StoreMetaDataService;
  private readonly biService: BIService;
  private readonly orderService: OrderService;
  private readonly modalManagerService: ModalManagerService;
  private readonly minimumOrderAmountService: MinimumOrderAmountService;
  private storeMetaData: Awaited<ReturnType<StoreMetaDataApi['getStoreMetaData']>>;
  private controllerPromise: Promise<ExpressCheckoutButtonsController>;

  constructor(
    controllerParams: ControllerParams,
    controllerApi: ICartControllerApi,
    private readonly siteStore: SiteStore,
    {
      cartService,
      checkoutNavigationService,
      storeMetaDataService,
      biService,
      orderService,
      navigationService,
      modalManagerService,
      minimumOrderAmountService,
    }: {
      cartService: CartService;
      checkoutNavigationService: CheckoutNavigationService;
      storeMetaDataService: StoreMetaDataService;
      biService: BIService;
      orderService: OrderService;
      navigationService: NavigationService;
      modalManagerService: ModalManagerService;
      minimumOrderAmountService: MinimumOrderAmountService;
    },
    private readonly styleSettingsService: StyleSettingsService
  ) {
    this.cartService = cartService;
    this.checkoutNavigationService = checkoutNavigationService;
    this.navigationService = navigationService;
    this.storeMetaDataService = storeMetaDataService;
    this.orderService = orderService;
    this.biService = biService;
    this.modalManagerService = modalManagerService;
    this.minimumOrderAmountService = minimumOrderAmountService;

    void this.initializeExpressCheckoutButtonsController(controllerParams);
  }

  public init() {
    /* istanbul ignore else */
    if (this.styleSettingsService.shouldShowExpressCheckout) {
      void this.controllerPromise.then((controller) => controller.init());
    }
  }

  private readonly initializeExpressCheckoutButtonsController = (controllerParams: ControllerParams) => {
    this.controllerPromise = (async () => {
      this.storeMetaData = await this.storeMetaDataService.get();
      return createExpressCheckoutButtonsController({
        getCurrency: () => this.cartService.cart?.currencyFormat?.code,
        demoMode: this.siteStore.isEditorMode(),
        controllerConfig: controllerParams.controllerConfig,
        httpClient: controllerParams.flowAPI.httpClient,
        onClick: () => {
          const {activePaymentMethods} = this.storeMetaData;
          this.navigationService.isNavigationToCheckoutInProcess = true;

          const isPickupFlow = this.orderService.isPickup;
          const isWithOfflinePayment =
            activePaymentMethods?.filter((paymentMethod) => paymentMethod.type === PaymentMethodType.offline).length >
            0;
          this.biService.clickOnCheckoutWithEWallet(
            this.cartService.cart,
            this.cartService.cartType,
            activePaymentMethods,
            isWithOfflinePayment,
            isPickupFlow
          );

          const {canCheckout, modalType, shouldNotify} = this.checkIsAllowedToCheckout();
          if (!canCheckout) {
            /* istanbul ignore else */
            if (shouldNotify) {
              const isPremium = this.storeMetaData?.isPremium;
              this.checkoutNavigationService.sendLostBusinessEmail(isPremium);
            }
            void this.checkoutNavigationService.openModalByType(
              modalType,
              this.styleSettingsService.isEditorX,
              this.cartService.cart
            );
            this.navigationService.isNavigationToCheckoutInProcess = false;
          }
        },
        getCheckoutId: /* istanbul ignore next */ async () => {
          const createCheckoutResult = await this.cartService.createCheckout();
          if (typeof createCheckoutResult !== 'string' && createCheckoutResult?.error) {
            await handleCreateCheckoutErrors(
              this.checkoutNavigationService,
              this.styleSettingsService.isEditorX,
              this.cartService.cart,
              createCheckoutResult.error as CreateCheckoutExceptions
            );

            this.navigationService.isNavigationToCheckoutInProcess = false;
            return Promise.resolve(undefined);
          } else {
            setTimeout(() => (this.navigationService.isNavigationToCheckoutInProcess = false), 5_000);
            return Promise.resolve(this.cartService.checkoutId);
          }
        },
        widgetId: StoresWidgetID.CART,
        locale: controllerParams.flowAPI.translations.config.language,
      });
    })();
    return this.controllerPromise;
  };

  private readonly checkIsAllowedToCheckout = () => {
    const {hasCreatedPaymentMethods, canStoreShip, isPremium} = this.storeMetaData ?? /* istanbul ignore next */ {};

    const {canCheckout, modalType, shouldNotify} = this.checkoutNavigationService.checkIsAllowedToCheckout({
      areAllItemsDigital: this.cartService.isNonShippableCart,
      hasShippableItems: this.cartService.hasShippableItems,
      isPremium,
      canStoreShip,
      hasCreatedPaymentMethods,
      canShipToDestination: true,
    });
    return {canCheckout, modalType, shouldNotify};
  };

  private get cashierExpressEnabled() {
    return this.cartService.areAllItemsInStock;
  }

  private readonly handlePaymentMethodError = () => {
    const queryParamsService = new QueryParamsService(this.siteStore);
    const pmName = queryParamsService.getQueryParam('pmName');
    const pmUrl = queryParamsService.getQueryParam('pmUrl') ?? '';

    if (pmName !== undefined && pmName !== '') {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises
      this.modalManagerService.modalManger.openErrorWithPaymentMethod({
        pmName: pmName.replace(/\//g, ''),
        pmUrl: pmUrl.replace(/\//g, ''),
      });
    }
  };

  private shouldRequestShipping(): boolean {
    const {shouldShowShipping} = this.styleSettingsService;
    const isPickup = this.orderService.isPickup && shouldShowShipping;
    const hasShippableItems = this.cartService.hasShippableItems;
    return hasShippableItems && !isPickup;
  }

  private getButtonStyle(): ButtonStyle {
    return {
      shape: this.styleSettingsService.dynamicPaymentMethodsShape === DynamicPaymentMethodsShape.pill ? 'pill' : 'rect',
      height: 42,
    };
  }

  private getButtonTheme(): Theme {
    return this.styleSettingsService.dynamicPaymentMethodsTheme === DynamicPaymentMethodsTheme.light ? 'light' : 'dark';
  }

  private shouldShowDynamicPaymentOptions(): boolean {
    return (
      !this.minimumOrderAmountService.shouldDisableCheckout &&
      !this.cartService?.cart?.totals?.payLater &&
      !this.cartService.hasErrorViolations
    );
  }

  private getProps() {
    return {
      handlePaymentMethodError: this.handlePaymentMethodError,
      shouldShowDynamicPaymentOptions: this.shouldShowDynamicPaymentOptions(),
      dynamicPaymentOptionsProps: {
        buttonStyle: this.getButtonStyle(),
        getButtonTheme: this.getButtonTheme(),
        shouldRequestShipping: this.shouldRequestShipping(),
        canCheckout: this.checkIsAllowedToCheckout()?.canCheckout,
      },
    };
  }

  public toProps(): Partial<ReturnType<CashierExpressStore['getProps']>> {
    if (this.cashierExpressEnabled) {
      return this.getProps();
    }

    return {
      shouldShowDynamicPaymentOptions: false,
      handlePaymentMethodError: this.handlePaymentMethodError,
    };
  }
}
